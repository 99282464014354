define("discourse/plugins/discourse-ai-topic-summary/discourse/components/ai-topic-summary", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _ajax, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicSummaryComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "localDownVotes", [_tracking.tracked]))();
    #localDownVotes = (() => (dt7948.i(this, "localDownVotes"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "downVotes", [_tracking.tracked]))();
    #downVotes = (() => (dt7948.i(this, "downVotes"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "text", [_tracking.tracked]))();
    #text = (() => (dt7948.i(this, "text"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "topic_id", [_tracking.tracked]))();
    #topic_id = (() => (dt7948.i(this, "topic_id"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "voted", [_tracking.tracked]))();
    #voted = (() => (dt7948.i(this, "voted"), void 0))();
    constructor() {
      super(...arguments);
      this.topic_id = this.router.currentRoute.parent.params.id;
      const topicAiSummaryDataPath = `/ai-topic-summary/ai_summary/${this.topic_id}.json`;
      (0, _ajax.ajax)(topicAiSummaryDataPath).then(response => {
        if (response.ai_summary !== null) {
          this.text = response.ai_summary.text;
          this.downVotes = response.ai_summary.downvoted;
        }
      });
      this.localDownVotes = typeof this.downVotes !== "undefined" ? this.downVotes.length || 0 : 0;
      if (this.currentUser) {
        this.voted = typeof this.downVotes !== "undefined" ? this.downVotes.includes(this.currentUser.id) : false;
      } else {
        this.voted = true;
      }
    }
    get show() {
      return this.siteSettings.ai_topic_summary_enabled && this.text && this.currentUser;
    }
    downVote() {
      this.localDownVotes++;
      this.voted = true;
      (0, _ajax.ajax)("/ai-topic-summary/downvote", {
        type: "POST",
        data: {
          username: this.currentUser.username,
          topic_id: this.topic_id
        },
        returnXHR: true
      }).catch(function (error) {
        (0, _ajaxError.popupAjaxError)(error);
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "downVote", [_object.action]))();
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="ai-topic-summary-component">
            <div class="ai-summary-box">
              <span
                class="ai-summary-title"
                title={{i18n "ai_topic_summary.heading.title"}}
              >{{i18n "ai_topic_summary.heading.text"}}</span><span
                class="ai-summary"
              >{{this.text}}</span>
              <span class="ai-summary-downvote">
                <DButton
                  class="ai-summary-downvote-button"
                  @icon="thumbs-down"
                  @title="ai_topic_summary.downvote"
                  @disabled={{this.voted}}
                  @action={{action this.downVote}}
                /><span
                  class="ai-summary-downvote-count"
                >{{this.localDownVotes}}</span>
              </span>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "Zol9J9VK",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"ai-topic-summary-component\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"ai-summary-box\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"ai-summary-title\"],[15,\"title\",[28,[32,0],[\"ai_topic_summary.heading.title\"],null]],[12],[1,[28,[32,0],[\"ai_topic_summary.heading.text\"],null]],[13],[10,1],[14,0,\"ai-summary\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n          \"],[10,1],[14,0,\"ai-summary-downvote\"],[12],[1,\"\\n            \"],[8,[32,1],[[24,0,\"ai-summary-downvote-button\"]],[[\"@icon\",\"@title\",\"@disabled\",\"@action\"],[\"thumbs-down\",\"ai_topic_summary.downvote\",[30,0,[\"voted\"]],[28,[31,1],[[30,0],[30,0,[\"downVote\"]]],null]]],null],[10,1],[14,0,\"ai-summary-downvote-count\"],[12],[1,[30,0,[\"localDownVotes\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\",\"action\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai-topic-summary/discourse/components/ai-topic-summary.js",
      "scope": () => [_i18n.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicSummaryComponent;
});